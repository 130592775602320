import { Config } from "@cryptr/cryptr-react";

type NODE_ENV = "production" | "development";

const config = {
  env: (import.meta.env.DEV ? "development" : "production") as NODE_ENV,
  api: import.meta.env.VITE_REACT_APP_API_URL ?? "https://api.kamae.io",
  app: import.meta.env.VITE_REACT_APP_APP_URL ?? "https://app.kamae.io",
  // Fallback to PostHog Test project
  posthogKey:
    import.meta.env.VITE_REACT_APP_POSTHOG_KEY ??
    "phc_8VrHwQadmAII7pU9v5qhDHskVtMfQ0LjBobnLV4rHQ0",
  // Fallback to Intercom Test project
  intercomKey: import.meta.env.VITE_REACT_APP_INTERCOM_KEY ?? "we22i5ih",
  // Fallback to Faro "Frontend (test)" project
  grafanaFaroUrl:
    import.meta.env.VITE_REACT_APP_GRAFANA_FARO_URL ??
    "https://faro-collector-prod-eu-west-2.grafana.net/collect/0bfd2630650cc18a1205de44b416984f",
  kamaeEssentialUrl: "https://kamae.fr/axa-offre-kamae-essential/",
};

export const auth0Config = {
  login: import.meta.env.VITE_REACT_APP_AUTH0_URL,
  loginDomain: import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID,
};

// tag::CRYPTR_CONF[]
export const cryptrConf: Config = {
  // Root URL of our front app
  audience: config.app,

  // Reference to our front app id
  client_id:
    import.meta.env.VITE_REACT_APP_CRYPTR_CLIENT_ID ??
    "3227c76a-0de5-47c8-b9ef-d37f86bd67ec",

  // Desired redirection URL after authentication process
  default_redirect_uri: config.app,

  // Default locale
  default_locale: "fr",

  // Did we have a dedicated server in Cryptr
  dedicated_server: true,

  // Defines if SLO (Single Logout) has to be done on SSO logout process
  default_slo_after_revoke: false,

  // Set to true if debug
  telemetry: false,

  // URL of our Cryptr service
  cryptr_base_url: "https://fi-staging.cryptr.dev",

  // Reference to our company entity
  tenant_domain: "kamae",

  // Authentication flow (Deprecated in progress)
  fixed_pkce: true,
};
// end::CRYPTR_CONF[]

export default config;
